import React, { useState, useEffect } from "react";
import FlexSearch from "flexsearch";
import ReactGA from "react-ga4";

export default function FaqNewSimplified() {
    ReactGA.send({
        hitType: "pageview",
        page: "/faq",
        title: "FAQ Simplificado / Sobre a AMC",
    });

    const faqData = [
        {
            question: "O que é o Alpha Monkey Club (AMC)?",
            answer:
                "O Alpha Monkey Club é uma plataforma que realiza análise quantitativa preditiva, utilizando inteligência e automação para simplificar investimentos. Oferecemos estratégias baseadas em dados que proporcionam estabilidade e resultados consistentes.",
        },
        {
            question: "Qual é a parceria do Alpha Monkey Club com a NVIDIA?",
            answer:
                "O Alpha Monkey Club faz parte do programa NVIDIA Inception, que nos proporciona acesso às melhores tecnologias de inteligência artificial e aprendizado de máquina. Essa parceria nos ajuda a criar modelos ainda mais precisos e eficientes.",
        },
        {
            question: "Qual é a origem do nome 'Alpha Monkey Club'?",
            answer:
                "O termo 'Alpha' reflete a busca por desempenho superior no mercado financeiro. Já 'Monkey Club' surgiu como uma brincadeira interna, representando a leveza e criatividade da equipe ao criar soluções inovadoras para o mercado financeiro.",
        },
        {
            question: "O que é mini índice?",
            answer:
                "O mini índice é um contrato futuro negociado na bolsa de valores que permite especular sobre a movimentação do índice Bovespa. Ele é chamado de 'mini' porque representa uma fração do contrato cheio, tornando-o mais acessível para pequenos investidores.",
        },
        {
            question: "O que é mini dólar?",
            answer:
                "O mini dólar é um contrato futuro negociado na bolsa de valores que permite especular sobre a movimentação do dólar americano em relação ao real. Assim como o mini índice, ele é uma versão reduzida do contrato cheio, ideal para investidores menores.",
        },
        {
            question: "Quais são as métricas usadas no Alpha Monkey Club?",
            answer: `
                <ul>
                    <li><strong>Acurácia:</strong> Mede o percentual de operações corretas realizadas pelo modelo.</li>
                    <li><strong>Payoff:</strong> Mostra a relação entre o lucro médio e a perda média das operações.</li>
                    <li><strong>Drawdown:</strong> Representa a maior perda acumulada em um período, ajudando a avaliar o risco.</li>
                </ul>
            `,
        },
        {
            question: "O que são alphas?",
            answer:
                "Os alphas são modelos matemáticos baseados em machine learning, criados para prever movimentos do mercado financeiro. Eles realizam operações automaticamente, utilizando dados históricos para tomar decisões estratégicas em horários pré-definidos.",
        },
        {
            question: "O que é Big Data?",
            answer:
                "Big Data refere-se ao processamento e análise de grandes volumes de dados. Ele permite identificar padrões e insights em informações complexas, que seriam difíceis de analisar manualmente.",
        },
        {
            question: "O que é Machine Learning?",
            answer:
                "Machine Learning é um campo da inteligência artificial que ensina computadores a aprenderem a partir de dados, sem serem explicitamente programados. No Alpha Monkey Club, utilizamos Machine Learning para criar alphas que fazem previsões precisas no mercado financeiro.",
        },
        {
            question: "Como os alphas são criados?",
            answer:
                "Coletamos dados de todos os ativos da Ibovespa desde 2000. Os modelos são treinados até 2021 e validados a partir de 2022, garantindo confiabilidade e precisão nas operações.",
        },
        {
            question: "O que é overfitting e como evitamos?",
            answer:
                "Overfitting ocorre quando o modelo se ajusta demais aos dados de treinamento, perdendo eficiência em dados novos. Evitamos isso separando os dados em treinamento, teste e validação, garantindo resultados robustos.",
        },
        {
            question: "Por que não usamos stop loss?",
            answer:
                "Nossos alphas têm drawdown controlado, tanto em relação ao valor quanto ao tempo. Isso significa que qualquer perda temporária é prevista, limitada e gerenciada dentro de parâmetros estabelecidos historicamente. O drawdown não representa uma perda permanente, mas sim uma oscilação momentânea que respeita limites claros, conforme validado desde 2022. Com esses controles, a necessidade de um stop loss é eliminada, pois os alphas demonstram estabilidade e retornos consistentes mesmo em cenários adversos.",
        },
        {
            question: "Por que usamos garantias e não o valor total do ativo?",
            answer:
                "Ao operar contratos futuros como mini índice e mini dólar, é necessário apenas um depósito de garantia, que é uma fração do valor total do contrato. Isso reduz o capital necessário para a operação e oferece maior liquidez ao investidor.",
        },
        {
            question: "O que acontece com o valor de garantia depositado na corretora?",
            answer:
                "O valor de garantia pode ser retirado a qualquer momento, e o lucro excedente pode ser reinvestido ou sacado. Oferecemos liquidez diária.",
        },
        {
            question: "O que é análise quantitativa e quais os tipos?",
            answer: `
                <ul>
                    <li><strong>Análise Descritiva:</strong> Baseia-se em dados históricos para fornecer informações sobre o que aconteceu no passado, auxiliando humanos na tomada de decisões.</li>
                    <li><strong>Análise Preditiva:</strong> Utiliza modelos matemáticos para prever cenários futuros de forma totalmente automatizada. No caso do Alpha Monkey Club, nossos alphas realizam previsões precisas, sem intervenção humana, usando cálculos estatísticos avançados.</li>
                </ul>
            `,
        },
        {
            question: "O que é análise técnica?",
            answer:
                "Análise técnica é o estudo de gráficos e padrões de preços para prever movimentos futuros no mercado financeiro. Ela se baseia no histórico de preços, volume e indicadores técnicos, sem levar em conta os fundamentos econômicos dos ativos.",
        },
        {
            question: "Qual é a diferença entre análise técnica e ciência de dados?",
            answer:
                "Enquanto a análise técnica utiliza gráficos e padrões históricos para prever movimentos de mercado, a ciência de dados aplica modelos matemáticos e estatísticos avançados para analisar grandes volumes de dados. No Alpha Monkey Club, usamos ciência de dados para criar alphas que são mais robustos e precisos do que estratégias baseadas unicamente em análise técnica.",
        },
        {
            question: "Posso perder todo o meu valor de garantia?",
            answer:
                "Não. Nossos alphas têm controle rigoroso de drawdown, que é exibido no site desde 2022, com dados de validação detalhados. Pense nos alphas como um estudante de colegial que foi treinado e testado durante o ensino médio, mas provado de verdade nos vestibulares. Desde 2022, esses alphas têm demonstrado sucesso consistente em cenários adversos e variados, garantindo estabilidade e robustez em suas operações.",
        },
        {
            question: "Posso perder todo o meu valor de garantia em situações de crise?",
            answer:
                "Não. Nossos alphas e portfólios foram submetidos a testes caóticos e têm um histórico comprovado de estabilidade. A acurácia, drawdown e payoff foram validados em períodos distintos, incluindo crises e situações políticas adversas. Isso demonstra a robustez do alpha, que continua a entregar retornos consistentes e estáveis independentemente do cenário econômico.",
        },
        {
            question: "Qual é o risco?",
            answer:
                "O risco é basicamente nulo, embora nenhum investimento tenha risco zero. Nossos alphas possuem drawdown limitado, validado em históricos desde 2022, e foram submetidos a testes caóticos que simulam variações extremas. Mesmo nesses cenários, os alphas demonstraram retornos extraordinários e consistentes, proporcionando uma segurança incomparável para os investidores.",
        },
        {
            question: "O que é risco segurado?",
            answer:
                "Risco segurado significa que o investidor atinge um ponto em que já recuperou o valor inicial de garantia depositado. No Alpha Monkey Club, isso geralmente ocorre no primeiro mês de operação, dado que o retorno médio mensal é muitas vezes próximo ou superior ao valor da garantia. Além disso, o tempo máximo de drawdown no histórico demonstra a estabilidade do alpha, mostrando que o drawdown possui limites claros tanto de valor quanto de duração. Após atingir o risco segurado, qualquer retorno adicional representa lucro, proporcionando ainda mais segurança ao investimento.",
        },
    ];

    const [searchQuery, setSearchQuery] = useState(""); // Estado para o texto da busca
    const [filteredFaqs, setFilteredFaqs] = useState(faqData); // Estado para os resultados
    const [index, setIndex] = useState(null); // Índice de busca do FlexSearch

    // Configura o índice FlexSearch ao carregar o componente
    useEffect(() => {
        const newIndex = new FlexSearch.Document({
            tokenize: "forward", // Divide palavras por prefixos
            cache: true, // Usa cache para melhorar a performance
            document: {
                id: "id", // Identificador único para cada item
                index: ["question", "answer"], // Campos a serem indexados
                store: ["question", "answer"], // Campos a serem retornados
            },
        });

        // Adiciona os dados ao índice
        faqData.forEach((item, idx) =>
            newIndex.add({
                id: idx,
                question: item.question,
                answer: item.answer,
            })
        );

        setIndex(newIndex); // Define o índice no estado
    }, []);

    // Função para lidar com a entrada de busca
    const handleSearch = (e) => {
        const query = e.target.value;
        setSearchQuery(query);

        if (query.trim() === "" || !index) {
            setFilteredFaqs(faqData); // Mostra tudo se a busca estiver vazia
        } else {
            const results = index.search(query, { limit: 10 }); // Busca os 10 primeiros resultados
            const matchedFaqs = results.map((result) => result.result).flat();
            setFilteredFaqs(matchedFaqs.map((id) => faqData[id]));
        }
    };

    return (
        <div className="pt-6 container">
            <div className="text-900 font-bold text-5xl mb-3">Perguntas Frequentes</div>

            {/* Campo de pesquisa */}
            <input
                type="text"
                value={searchQuery}
                onChange={handleSearch}
                placeholder="Pesquise por uma pergunta..."
                className="mb-4 p-2 border rounded"
            />

            {/* Resultados da busca */}
            <div className="flex flex-column">
                {filteredFaqs.map((faq, index) => (
                    <div key={index} className="mb-4">
                        <div className="font-bold text-lg mb-2">{faq.question}</div>
                        <div
                            className="text-700"
                            dangerouslySetInnerHTML={{ __html: faq.answer }}
                        ></div>
                    </div>
                ))}
                {filteredFaqs.length === 0 && (
                    <div className="text-700">Nenhuma pergunta encontrada.</div>
                )}
            </div>
        </div>
    );
}
