import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";

const COLORS = {
    portfolio: "#3b82f6", // Cor do Portfólio
};

export default function PortfolioMetrics({ portfolio }) {
    const [helpVisible, setHelpVisible] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [displayedText, setDisplayedText] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);
    const [drawdownIndex, setDrawdownIndex] = useState(0); // Índice para alternar entre os valores de drawdown
    const typingSpeed = 3; // Velocidade de digitação (ms por caractere)
    const scrollRef = useRef(null); // Ref para o div que conterá o texto

    function formatBRL(number) {
        if (number === undefined || number === null) {
            return "R$ 0,00";
        }
        return number.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
        });
    }

    const toggleHelp = (section) => {
        setHelpVisible(helpVisible === section ? null : section);
    };

    const openModal = (content) => {
        setModalContent(content);
        setDisplayedText(''); // Reseta o texto mostrado
        setCurrentIndex(0); // Reseta o índice atual para o efeito de digitação
        setIsModalOpen(true);
    };

    useEffect(() => {
        if (isModalOpen && currentIndex < modalContent.length) {
            const timeout = setTimeout(() => {
                setDisplayedText((prev) => prev + modalContent[currentIndex]);
                setCurrentIndex((prev) => prev + 1);
                if (scrollRef.current) {
                    scrollRef.current.scrollTop = scrollRef.current.scrollHeight; // Rola para o final
                }
            }, typingSpeed);

            return () => clearTimeout(timeout);
        }
    }, [isModalOpen, currentIndex, modalContent]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                closeModal();
            }
        };

        if (isModalOpen) {
            document.addEventListener('keydown', handleKeyDown);
        } else {
            document.removeEventListener('keydown', handleKeyDown);
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isModalOpen]);

    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent('');
        setDisplayedText('');
        setCurrentIndex(0);
    };

    const renderDrawdown = () => {
        const drawdownList = portfolio.metrics.drawdown_list || [];
        const currentDrawdown = drawdownList[drawdownIndex] || {};
        const drawdownValue = currentDrawdown.drawdown_em_reais || 0;
        const drawdownDuration = currentDrawdown.duration || 0;

        return (
            <span>
                <strong>{`Drawdown ${drawdownIndex + 1}:`}</strong> {formatBRL(drawdownValue)} ({drawdownDuration} dias)
            </span>
        );
    };

    return (
        <div className="shadow-1 br-1 p-2 mt-2 mb-2">
            {/* Modal Component */}
            {isModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button onClick={closeModal}>&times;</button>
                        </div>
                        <div
                            ref={scrollRef}
                            className="modal-body"
                            style={{ overflowY: 'auto', maxHeight: '400px' }}
                        >
                            {displayedText}
                        </div>
                        <div className="modal-footer" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                            <Button
                                label="Fechar"
                                type="button"
                                className="p-button-raised"
                                onClick={closeModal}
                            />
                        </div>
                    </div>
                </div>
            )}

            {/* Métricas organizadas */}
            <div className="shadow-1 br-1 p-2 mt-2 mb-2">
                <div className="prsy-grid prsy-col4 justify-content-between text-center mt-3">
                    <div className="flex flex-column border-right-1 border-200">
                        <span style={{ fontSize: "1.2em", fontWeight: "bold", color: COLORS.portfolio }}>
                            {formatBRL(portfolio.metrics.total_retorno)}
                        </span>
                        <span style={{ fontSize: "0.7em" }}>Retorno Total</span>
                    </div>
                    <div className="flex flex-column border-right-1 border-200">
                        <span style={{ fontSize: "1.2em", fontWeight: "bold", color: COLORS.portfolio }}>
                            {formatBRL(portfolio.metrics.drawdown_maximo_em_reais)}
                        </span>
                        <span style={{ fontSize: "0.7em" }}>Drawdown Máximo</span>
                    </div>
                    <div className="flex flex-column border-right-1 border-200">
                        <span style={{ fontSize: "1.2em", fontWeight: "bold", color: COLORS.portfolio }}>
                            {formatBRL(portfolio.metrics.max_capital_required)}
                        </span>
                        <span style={{ fontSize: "0.7em" }}>Capital Recomendado</span>
                    </div>
                    <div className="flex flex-column">
                        <span style={{ fontSize: "1.2em", fontWeight: "bold", color: COLORS.portfolio }}>
                            {formatBRL(portfolio.metrics.payoff * 21)}
                        </span>
                        <span style={{ fontSize: "0.7em" }}>Retorno Médio Mensal</span>
                    </div>
                </div>
            </div>

            {/* Métricas organizadas em uma linha com 4 colunas */}
            <div className="shadow-1 br-1 p-2 mt-2 mb-2">
                <div className="prsy-grid prsy-col4 justify-content-between text-center mt-3">
                    {/* Payoff */}
                    <div className="flex flex-column border-right-1 border-200">
                        <span style={{ fontSize: "1.2em", fontWeight: "bold", color: COLORS.portfolio }}>
                            {formatBRL(portfolio.metrics.payoff)}
                        </span>
                        <span style={{ fontSize: "0.7em" }}>Payoff (dia útil)</span>
                    </div>

                    {/* Tempo Máximo de Drawdown */}
                    <div className="flex flex-column border-right-1 border-200">
                        <span style={{ fontSize: "1.2em", fontWeight: "bold", color: COLORS.portfolio }}>
                            {portfolio.metrics.drawdown_list && portfolio.metrics.drawdown_list.length > 0
                                ? `${portfolio.metrics.drawdown_list[0].duration} dias`
                                : "Dados indisponíveis"}
                        </span>
                        <span style={{ fontSize: "0.7em" }}>Tempo Máximo Drawdown</span>
                    </div>

                    {/* Retorno como Percentual do Drawdown Máximo */}
                    <div className="flex flex-column border-right-1 border-200">
                        <span style={{ fontSize: "1.2em", fontWeight: "bold", color: COLORS.portfolio }}>
                            {portfolio.metrics.drawdown_maximo_em_reais > 0
                                ? `${((portfolio.metrics.total_retorno / portfolio.metrics.drawdown_maximo_em_reais) * 100).toFixed(2)}%`
                                : "N/A"}
                        </span>
                        <span style={{ fontSize: "0.7em" }}>Retorno (% Drawdown Máximo)</span>
                    </div>

                    {/* Retorno como Percentual do Capital Recomendado */}
                    <div className="flex flex-column">
                        <span style={{ fontSize: "1.2em", fontWeight: "bold", color: COLORS.portfolio }}>
                            {portfolio.metrics.acuracia !== undefined
                                ? `${(portfolio.metrics.acuracia * 100).toFixed(2)}%`
                                : "N/A"}
                        </span>
                        <span style={{ fontSize: "0.7em" }}>Assertividade</span>
                    </div>
                </div>
            </div>

        </div>
    );
}
