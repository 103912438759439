import React from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import FaqCategory from "./Faq/FaqCategory";
import ReactGA from "react-ga4";

export default function ComoFunciona() {
    ReactGA.send({
        hitType: "pageview",
        page: "/how-to",
        title: "Quem Somos / Sobre a AMC",
    });

    const howTo = [
        {
            category: "O que é o Alpha Monkey Club?",
            subcategories: [
                {
                    subcategory: "Visão Geral",
                    questions: [
                        {
                            question: "O que é o Alpha Monkey Club?", answer: "O Alpha Monkey Club é uma plataforma que combina inteligência artificial (IA), análise quantitativa e automação para oferecer estratégias robustas no mercado financeiro. Somos também uma empresa parceira da NVIDIA no programa NVIDIA Inception, o que nos permite desenvolver modelos matemáticos avançados e utilizar as mais recentes tecnologias para análise quantitativa preditiva. Tudo isso é feito de forma completamente automatizada, sem intervenção humana.",
                        },
                        {
                            question: "Como as decisões são tomadas no Alpha Monkey Club?",
                            answer: "Todas as decisões operacionais são realizadas exclusivamente pela inteligência artificial. Isso elimina o fator emocional humano, garantindo que as estratégias sejam racionais, consistentes e baseadas em dados.",
                        },
                        {
                            question: "O que fazemos no Alpha Monkey Club?", answer: "Realizamos análise quantitativa em grande escala, utilizando tecnologias de Big Data para processar e interpretar vastas quantidades de dados financeiros. Isso nos permite criar estratégias adaptadas às condições do mercado. Além disso, somos uma empresa parceira da NVIDIA no programa NVIDIA Inception, dedicado ao desenvolvimento de modelos matemáticos para o mercado financeiro e análise quantitativa preditiva.",
                        },
                        {
                            question: "O que é Big Data e como utilizamos essa tecnologia?",
                            answer: "Big Data refere-se ao processamento e análise de grandes volumes de dados. No Alpha Monkey Club, utilizamos Big Data para alimentar nossos modelos de Machine Learning, extraindo insights essenciais que garantem estratégias eficazes e previsíveis.",
                        },
                        {
                            question: "O que é Machine Learning?",
                            answer: "Machine Learning é uma área da inteligência artificial que permite que máquinas aprendam e melhorem automaticamente com base em dados, sem serem explicitamente programadas. No Alpha Monkey Club, usamos Machine Learning para criar modelos que analisam padrões de mercado e fazem previsões precisas.",
                        },
                        {
                            question: "Por que o Alpha Monkey Club é diferente de outras plataformas?",
                            answer: "Nosso diferencial é a ausência de intervenção humana nas decisões, o uso de modelos validados com rigor e um ambiente operacional seguro. Garantimos consistência e resultados confiáveis, evitando erros emocionais ou subjetivos.",
                        },
                    ],
                },
            ],
        },
        {
            category: "Métricas",
            subcategories: [
                {
                    subcategory: "Definições",
                    questions: [
                        {
                            question: "O que é Payoff?", answer: "Payoff é a relação entre os ganhos e as perdas de uma estratégia. Ele mede o equilíbrio entre as operações vencedoras e perdedoras, sendo uma métrica essencial para avaliar a eficiência. No Alpha Monkey Club, o Payoff se mantém estável em diferentes períodos, como os disponíveis no site: análises anuais (2022, 2023, 2024), último ano, últimos 6 meses, últimos 3 meses e último mês. Isso demonstra a consistência de nossas estratégias.",
                        },
                        {
                            question: "O que é Drawdown?", answer: "Drawdown é a redução temporária do capital após uma sequência de perdas. Não representa uma perda definitiva, pois o capital pode ser recuperado com operações bem-sucedidas. O Alpha Monkey Club garante que o Drawdown se mantenha controlado e estável em diferentes períodos, como os disponíveis no site: análises anuais (2022, 2023, 2024), último ano, últimos 6 meses, últimos 3 meses e último mês.",
                        },
                        {
                            question: "O que é acurácia (assertividade)?", answer: "A acurácia mede a porcentagem de operações que atingem os objetivos esperados. É uma métrica-chave para avaliar a eficácia de um Alpha. No Alpha Monkey Club, garantimos que a acurácia se mantenha consistente em diferentes períodos, como os disponíveis no site: análises anuais (2022, 2023, 2024), último ano, últimos 6 meses, últimos 3 meses e último mês.",
                        },
                        {
                            question: "Importância do controle do Drawdown?", answer: "No Alpha Monkey Club, os Alphas possuem drawdowns controlados estatisticamente e que podem ser visualizados no site em períodos distintos. O drawdown é controlado e limitado, demonstrado através do histórico do período de validação tanto em valor quanto em tempo que esteve em drawdown. Isso faz com que o investimento tenha risco nulo, já que a perda temporária (drawdown) é gerenciada com uma metodologia científica robusta no controle de valor e período em drawdown.",
                        },
                    ],
                },
                {
                    subcategory: "Nossos Diferenciais",
                    questions: [
                        {
                            question: "Por que o Alpha Monkey Club se destaca nessas métricas?",
                            answer: "Nosso controle de acurácia, drawdown e payoff é rigoroso. Monitoramos essas métricas constantemente, garantindo estabilidade e consistência para nossos investidores.",
                        },
                        {
                            question: "Como o controle do Drawdown beneficia o cliente?",
                            answer: "Controlar o Drawdown protege o capital do investidor e, em poucos meses, os lucros gerados superam o valor do Drawdown inicial, trazendo retornos adicionais com segurança.",
                        },
                    ],
                },
            ],
        },
        {
            category: "O que são Alphas?",
            subcategories: [
                {
                    subcategory: "Definição e Funcionamento",
                    questions: [
                        {
                            question: "O que é um Alpha no mercado financeiro?",
                            answer: "Alpha representa o retorno adicional que uma estratégia gera em comparação ao índice de referência do mercado, como o CDI. Ele mede o desempenho superior de uma estratégia.",
                        },
                        {
                            question: "Os Alphas são estratégias fixas?",
                            answer: "Não. Alphas são modelos dinâmicos e adaptáveis. Eles são criados para horários pré-determinados de entrada e saída no mercado, e a IA aprende a frequência e a volatilidade específica desses intervalos.",
                        },
                        {
                            question: "Como os Alphas são criados?",
                            answer: "Os Alphas são desenvolvidos a partir de modelos matemáticos de Machine Learning, treinados com dados históricos para identificar padrões e prever comportamentos futuros.",
                        },
                        {
                            question: "O que é análise quantitativa?",
                            answer: "É o uso de métodos matemáticos e estatísticos para processar dados financeiros, identificar padrões e tomar decisões baseadas em lógica e evidências.",
                        },
                        {
                            question: "O que é análise quantitativa descritiva?",
                            answer: "A análise descritiva examina dados históricos para entender padrões e tendências. Ela é frequentemente utilizada por humanos para tomar decisões com base em informações passadas.",
                        },
                        {
                            question: "O que é análise quantitativa preditiva?",
                            answer: "A análise preditiva utiliza modelos avançados para prever movimentos futuros no mercado. No Alpha Monkey Club, aplicamos esse método, assim como o Renaissance, do Jim Simons, para criar estratégias consistentes e eficazes.",
                        },
                    ],
                },
            ],
        },
        {
            category: "Alphas e Portfólios",
            subcategories: [
                {
                    subcategory: "Processos e Dados",
                    questions: [
                        {
                            question: "Como os Alphas são treinados?", answer: "O treinamento envolve coletar dados de todos os ativos disponíveis na B3 desde 2000 até 2021. Esses dados são divididos em duas partes: Treinamento, onde a IA aprende padrões, e Teste, onde avaliamos a eficácia do modelo em dados que ele não conhece. Esse processo rigoroso garante que os modelos sejam robustos e adaptáveis.",
                        },
                        {
                            question: "O que são dados de validação?",
                            answer: "Dados de validação são informações que não foram utilizadas no treinamento do modelo. Eles testam o desempenho do Alpha em condições reais, sendo o período exibido no site desde 2022.",
                        },
                    ],
                },
                {
                    subcategory: "Vantagens dos Portfólios",
                    questions: [
                        {
                            question: "Qual é a diferença entre treinamento, teste e validação e o backtesting de estratégias de trading normais?",
                            answer: "No treinamento, teste e validação, dividimos os dados em partes específicas para ensinar e avaliar o modelo, enquanto no backtesting de estratégias normais, simplesmente aplicamos a estratégia em dados históricos sem garantir que ela funcione em cenários futuros. O Alpha Monkey Club utiliza validação rigorosa para evitar armadilhas comuns do backtesting, como o overfitting, garantindo que os modelos sejam robustos em situações reais."
                        },
                        {
                            question: "O que é overfitting e como ele é resolvido no Alpha Monkey Club?",
                            answer: "Overfitting ocorre quando um modelo é ajustado demais aos dados históricos, tornando-se incapaz de generalizar em novos dados. No Alpha Monkey Club, evitamos isso com um processo rigoroso de treinamento, teste e validação, além de submeter os modelos matemáticos a situações aleatórias diversas para garantir sua robustez e eficácia."
                        },
                        {
                            question: "Por que criamos Portfólios?",
                            answer: "Portfólios são conjuntos de Alphas que trabalham juntos para aumentar a robustez e estabilidade dos resultados. Isso reduz os impactos de oscilações individuais."
                        },
                        {
                            question: "Quais vantagens os Portfólios oferecem?",
                            answer: "Resultados mais estáveis devido à diversificação de estratégias, redução do impacto de volatilidades individuais e rápido retorno que supera o capital inicial de garantia em poucos meses."
                        },
                    ],
                },
            ],
        },
        {
            category: "Como Comprar",
            subcategories: [
                {
                    subcategory: "Passos para Compra",
                    questions: [
                        {
                            question: "Como posso comprar um portfólio no Alpha Monkey Club?",
                            answer: "O processo de compra no Alpha Monkey Club é simples e seguro. Você deve escolher um dos portfólios disponíveis, selecionar uma das corretoras integradas ao nosso sistema e efetuar o pagamento através do Stripe, nosso meio de pagamento seguro e eficiente."
                        },
                        {
                            question: "O que acontece após o pagamento?",
                            answer: "Após o pagamento, você receberá uma mensagem no WhatsApp de nosso assistente virtual com todas as instruções necessárias para configurar o MetaTrader5, que é a plataforma oficial de integração e automação utilizada pelas corretoras." 
                        }
                    ],
                },
            ],
        },
    ];

    return (
        <div className="pt-6 container">
            <div className="text-900 font-bold text-5xl mb-3">Quem Somos</div>
            <div className="flex flex-column">
                {howTo.map((faqCategory, index) => (
                    <FaqCategory key={index} faqCategory={faqCategory} />
                ))}
            </div>
        </div>
    );
}
