import React, { useState, useEffect } from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

export default function PlanComparison() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState([]);
    const navigate = useNavigate();

    ReactGA.send({
        hitType: "pageview",
        page: "/planos",
        title: "Planos",
    });

    useEffect(() => {
        const plansData = [
            {
                code: "PF150",
                description: "PF150",
                stripePriceId: "price_1QRzVMJp7YGMWabz1bD3qVuK",
                capitalRecommended: "R$ 4.315,00",
                maxCapitalRequired: "R$ 5.609,00",
                monthlyReturn: "R$ 3.258,00",
                price: "R$ 539,00",
                details: "Este portfólio é composto pelo Alphas do mini índice e mini dólar.",
                link: "/show-portifolio-automated/PF150",
            },
            {
                code: "PF250",
                description: "PF250",
                stripePriceId: "price_1QRzdWJp7YGMWabz03YycwsL",
                capitalRecommended: "R$ 4.409,00",
                maxCapitalRequired: "R$ 5.731,00",
                monthlyReturn: "R$ 5.389,00",
                price: "R$ 999,00",
                details: "Este portfólio é composto pelo Alphas do mini índice e mini dólar.",
                link: "/show-portifolio-automated/PF250",
            },
            {
                code: "PF450",
                description: "PF450",
                stripePriceId: "price_1QXT4PJp7YGMWabzx6bOEgDA",
                capitalRecommended: "R$ 7.021,00",
                maxCapitalRequired: "R$ 9.127,00",
                monthlyReturn: "R$ 9.567,00",
                price: "R$ 1.999,00",
                details: "Este portfólio é composto pelo Alphas do mini índice e mini dólar.",
                link: "/show-portifolio-automated/PF450",
            },
        ];

        setTimeout(() => {
            setData(plansData);
            setLoading(false);
        }, 1000); // Simulando o carregamento
    }, []);

    return (
        <>
            <div className="flex p-6 text-center justify-content-center">
                {loading && <ProgressSpinner />}
                {error && <>Erro ao carregar os Planos</>}
                {data && (
                    <div className="grid grid-nogutter col-12 gap-3 justify-content-center">
                        {data.map((plan, key) => (
                            <Card
                                key={key}
                                className="p-card p-component md:w-25rem flex flex-column justify-between"
                                style={{ minHeight: "500px" }}
                            >
                                <div className="p-card-body flex-grow-1">
                                    <div className="p-card-content">
                                        <div className="text-6xl text-blue-500 font-bold mb-3">{plan.description}</div>
                                        <div className="p-card-subtitle mb-4">
                                            <strong>Capital Recomendado:</strong> {plan.capitalRecommended}
                                        </div>
                                        <div className="p-card-subtitle mb-4">
                                            <strong>Capital Máximo Necessário:</strong> {plan.maxCapitalRequired}
                                        </div>
                                        <div className="p-card-subtitle mb-4">
                                            <strong>Retorno Mensal:</strong> {plan.monthlyReturn}
                                        </div>
                                        <div className="p-card-subtitle mb-4">
                                            <strong>Preço:</strong> {plan.price}
                                        </div>
                                        <div className="p-card-subtitle mb-4">
                                            <strong>Detalhes:</strong> {plan.details}
                                        </div>
                                    </div>
                                </div>
                                <div className="p-card-footer">
                                    <Button
                                        label={`Ver ${plan.description}`}
                                        type="button"
                                        className="p-button-raised w-full mt-4"
                                        onClick={() => navigate(plan.link)}
                                    />
                                </div>
                            </Card>
                        ))}
                    </div>
                )}
            </div>
        </>
    );
}
