import React from "react";

export default function SectionIntro() {
    return (
        <div className="surface-100 p-5 text-700 text-center">
            <h2 className="text-5xl text-blue-600 font-bold mb-3">Bem-vindo ao Alpha Monkey Club</h2>
            <p className="text-2xl text-700 mb-4">
                No <strong>Alpha Monkey Club</strong>, usamos tecnologia avançada para simplificar sua experiência no mercado financeiro. Nossos Planos de Portfólios combinam vários <strong>Alphas</strong>, sistemas inteligentes que analisam o mercado, tomam decisões de compra e venda e ajudam você a alcançar consistência nos resultados.
            </p>
            <p className="text-2xl text-700 mb-4">
                Como membros do <strong>programa NVIDIA Inception</strong>, levamos inovação e eficiência às suas operações financeiras. Cada Alpha é projetado para identificar oportunidades de mercado, gerando estratégias automatizadas que oferecem tranquilidade e resultados.
            </p>
            <p className="text-2xl text-700 mb-4">
                Nosso compromisso é democratizar o acesso à tecnologia, tornando o mercado mais acessível para investidores de todos os níveis. Com soluções práticas e eficientes, você pode investir de forma simples e segura.
            </p>
            <p className="text-2xl text-700">
                Junte-se ao Alpha Monkey Club e descubra como a automação pode transformar sua jornada no mercado financeiro.
            </p>
        </div>
    );
}
