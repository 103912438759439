import React from "react";
import ReactGA from "react-ga4";

export default function QuemSomos() {
    // Enviar evento para o Google Analytics
    ReactGA.send({
        hitType: "pageview",
        page: "/quem-somos",
        title: "Quem Somos",
    });

    // Dados das sessões com texto
    const sections = [
        {
            title: "O que é a Alpha Monkey Club?",
            description:
                "O Alpha Monkey Club é uma plataforma inovadora que utiliza tecnologia avançada para simplificar o investimento no mercado financeiro. Combinamos inteligência e automação para criar soluções que ajudam investidores de todos os níveis a alcançar resultados consistentes, mesmo sem experiência prévia ou grandes valores iniciais.",
        },
        {
            title: "Missão, Valores e Propósito",
            description:
                "Nossa missão é democratizar o acesso às melhores ferramentas de investimento, permitindo que qualquer pessoa possa investir com confiança e simplicidade. Valorizamos a inovação, a transparência e o compromisso com práticas responsáveis, ajudando nossos clientes a construir um futuro financeiro sólido e sustentável.",
        },
        {
            title: "Como Ajudamos Investidores",
            description:
                "Oferecemos Planos de Portfólios que combinam vários Alphas – algoritmos inteligentes que analisam o mercado, identificam oportunidades e realizam operações de compra e venda automaticamente. Isso permite que você invista com segurança, otimizando seus resultados e reduzindo os riscos.",
        },
        {
            title: "Como Funciona",
            description:
                "Com a Alpha Monkey Club, você não precisa de experiência no mercado. Nossos Planos de Portfólios oferecem automação completa das operações, permitindo que investidores com pouco capital inicial, geralmente entre 3.000 e 6.000 reais, obtenham lucros consistentes. Além disso, oferecemos suporte personalizado via WhatsApp para que você tenha toda a ajuda necessária em sua jornada.",
        },
        {
            title: "Parceria com a NVIDIA Inception",
            description:
                "Somos parceiros do programa NVIDIA Inception, o que nos coloca na vanguarda da tecnologia. Utilizamos inteligência artificial em todas as etapas de nossos sistemas, garantindo decisões mais precisas e eficientes para nossos clientes. Essa parceria reflete nosso compromisso com a inovação contínua e a excelência nos resultados.",
        },
    ];

    return (
        <div className="grid grid-nogutter surface-800 text-800">
            {sections.map((section, index) => (
                <div className="col-12 p-6 text-left" key={index}>
                    <section>
                        <span className="text-6xl text-blue-500 font-bold mb-3">{section.title}</span>
                        <div className="text-2xl mt-0 mb-4 text-0 line-height-3">{section.description}</div>
                    </section>
                </div>
            ))}
        </div>
    );
}
