import React, { useState, useEffect } from "react";
import { amc_api } from "../../utils/api_controller";
import { ProgressSpinner } from "primereact/progressspinner";
import JourneyChartAutomatedConsolidada from "./JourneyChartAutomatedConsolidada";

export default function ShowJornadaConsolidada() {
    const [selectedCorretora, setSelectedCorretora] = useState(8); // Default: XP Investimentos
    const [selectedAtivo, setSelectedAtivo] = useState("win"); // Default: Mini Índice
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [journeyData, setJourneyData] = useState(null);

    const corretoras = [
        { id: 6, nome: "Clear Investimentos" },
        { id: 9, nome: "Rico Investimentos" },
        { id: 8, nome: "XP Investimentos" },
    ];

    const ativos = [
        { id: "win", nome: "Mini Índice" },
        { id: "wdo", nome: "Mini Dólar" },
    ];

    const fetchData = () => {
        const endpoint = `/alphaportifolio/get_brokerage_user_journey/${selectedCorretora}/`;
        setLoading(true);
        setError(false);

        amc_api
            .get(endpoint)
            .then((res) => {
                if (!res.data) {
                    setJourneyData(null);
                } else {
                    setJourneyData(res.data);
                }
            })
            .catch(() => setError(true))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        fetchData();
    }, [selectedCorretora]);

    const formatBRL = (number) =>
        number.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
        });

    const formatPercentage = (number) =>
        (number * 100).toFixed(2) + "%";

    const getMetrics = () => {
        if (!journeyData) return null;
        return selectedAtivo === "win"
            ? journeyData.alpha_win?.metrics
            : journeyData.alpha_wdo?.metrics;
    };

    const getReturns = () => {
        if (!journeyData) return null;
        return selectedAtivo === "win"
            ? journeyData.alpha_win?.returns
            : journeyData.alpha_wdo?.returns;
    };

    const getCorretoraNome = (id) => {
        const corretora = corretoras.find((c) => c.id === id);
        return corretora ? corretora.nome : "a corretora selecionada";
    };

    return (
        <div className="container">
            {/* Seleção de Corretora */}
            <div className="text-center mb-4">
                <h2 className="text-3xl font-bold">Selecione a Corretora:</h2>
                <div className="flex justify-content-center gap-2 mt-3">
                    {corretoras.map((corretora) => (
                        <div
                            key={corretora.id}
                            className={`cursor-pointer p-2 shadow-1 br-1 ${
                                selectedCorretora === corretora.id ? "bg-bluegray-100 text-gray-400" : ""
                            }`}
                            onClick={() => setSelectedCorretora(corretora.id)}
                        >
                            {corretora.nome}
                        </div>
                    ))}
                </div>
            </div>

            {/* Seleção de Ativo */}
            <div className="text-center mb-4">
                <h2 className="text-3xl font-bold">Selecione o Ativo:</h2>
                <div className="flex justify-content-center gap-2 mt-3">
                    {ativos.map((ativo) => (
                        <div
                            key={ativo.id}
                            className={`cursor-pointer p-2 shadow-1 br-1 ${
                                selectedAtivo === ativo.id ? "bg-bluegray-100 text-gray-400" : ""
                            }`}
                            onClick={() => setSelectedAtivo(ativo.id)}
                        >
                            {ativo.nome}
                        </div>
                    ))}
                </div>
            </div>

            {/* Exibição de Dados */}
            {loading && <ProgressSpinner />}
            {error && <p className="text-center text-red-500">Erro ao carregar dados. Tente novamente.</p>}
            {!loading && !journeyData && (
                <p className="text-center text-xl">
                    Você não possui jornadas na <b>{getCorretoraNome(selectedCorretora)}</b>.
                </p>
            )}
            {journeyData && (
                <>
                    {/* Métricas */}
                    <div className="shadow-1 br-1 p-2 mt-2 mb-2">
                        {getMetrics() && (
                            <div className="prsy-grid prsy-col4 justify-content-between text-center">
                                <div className="flex flex-column border-right-1 border-200">
                                    <b>Acurácia</b>
                                    <span>{formatPercentage(getMetrics()?.acuracia || 0)}</span>
                                </div>
                                <div className="flex flex-column border-right-1 border-200">
                                    <b>Drawdown</b>
                                    <span>
                                        {formatBRL(getMetrics()?.drawdown_operacional_em_reais || 0)}
                                    </span>
                                </div>
                                <div className="flex flex-column border-right-1 border-200">
                                    <b>Payoff</b>
                                    <span>{getMetrics()?.payoff?.toFixed(2) || "N/A"}</span>
                                </div>
                                <div className="flex flex-column">
                                    <b>Total</b>
                                    <span>{formatBRL(getMetrics()?.total_em_reais || 0)}</span>
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Gráficos */}
                    <div className="flex flex-column w-full gap-3 mb-3">
                        {getReturns() && (
                            <JourneyChartAutomatedConsolidada
                                returns={getReturns()}
                            />
                        )}
                    </div>
                </>
            )}
        </div>
    );
}
