import React, { useState, useEffect } from 'react';
import { Chart } from "primereact/chart";

const COLORS = {
    portfolio: "#3b82f6", // Azul do Mini Dólar
    win: "#5fa8d3", // Azul claro para Mini Índice
    wdo: "#a3d8f4", // Azul mais claro
};

const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            labels: {
                color: "#1d4ed8",
            },
        },
    },
    scales: {
        x: {
            ticks: {
                color: "#1d4ed8",
            },
            grid: {
                offset: true,
            },
            categoryPercentage: 0.8,
            barPercentage: 0.9,
            offset: true,
        },
        y: {
            ticks: {
                color: "#1d4ed8",
            },
            beginAtZero: true,
        },
    },
    layout: {
        padding: {
            left: 20,
            right: 20,
        },
    },
    elements: {
        bar: {
            maxBarThickness: 40,
        },
    },
};

// Função para formatar as datas para dd/mm/yyyy
const formatDates = (dates) => {
    return dates.map((date) => {
        const [year, month, day] = date.split("T")[0].split("/");
        return `${day}/${month}/${year}`;
    });
};

const JourneyChartAutomated = ({ returns }) => {
    const [lineChartData, setLineChartData] = useState(null);
    const [barChartData, setBarChartData] = useState(null);

    useEffect(() => {
        // Configura o gráfico de linhas para as somas acumuladas
        setLineChartData({
            labels: formatDates(returns.datas),
            datasets: [
                {
                    label: "Soma Acumulada Alpha",
                    data: returns.retornos_em_reais_mercado,
                    borderColor: COLORS.win,
                    fill: false,
                    tension: 0.4,
                    type: 'line',
                },
                {
                    label: "Soma Acumulada Automação",
                    data: returns.retornos_em_reais_bot,
                    borderColor: COLORS.portfolio,
                    fill: false,
                    tension: 0.4,
                    type: 'line',
                },
            ],
        });

        // Configura o gráfico de barras para os retornos
        setBarChartData({
            labels: formatDates(returns.datas),
            datasets: [
                {
                    label: "Retornos Alpha",
                    data: returns.retornos_em_reais_mercado,
                    backgroundColor: COLORS.win,
                    type: 'bar',
                },
                {
                    label: "Retornos Automação",
                    data: returns.retornos_em_reais_bot,
                    backgroundColor: COLORS.portfolio,
                    type: 'bar',
                },
            ],
        });
    }, [returns]);

    return (
        <div>
            <div className="chart1">
                {lineChartData && (
                    <Chart
                        style={{ height: "300px", marginBottom: "1em" }}
                        type="line"
                        data={lineChartData}
                        options={chartOptions}
                    />
                )}
            </div>
            <div className="chart2">
                {barChartData && (
                    <Chart
                        style={{ height: "300px", marginBottom: "1em" }}
                        type="bar"
                        data={barChartData}
                        options={chartOptions}
                    />
                )}
            </div>
        </div>
    );
};

export default JourneyChartAutomated;
