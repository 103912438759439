import React, { useState } from "react";
import { useForm } from "react-hook-form";
import $ from "jquery";
import { ProgressSpinner } from "primereact/progressspinner";
import { Button } from "primereact/button";
import FormField from "./FormField";
import { amc_api } from "../../utils/api_controller";
import { useLocation } from "react-router-dom";
import { Dialog } from "primereact/dialog"; // Importando modal

export default function CreateAccountForm() {
    const [loading, setLoading] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [showTermsModal, setShowTermsModal] = useState(false); // Estado para controlar a exibição da modal
    let redirect = useLocation();

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({});

    const isLoading = (enable = true) => {
        if (enable) {
            setLoading(true);
            $("#switchForm").attr("disabled", true);
        } else {
            setLoading(false);
            $("#switchForm").attr("disabled", false);
        }
    };

    const onSubmit = (data) => {
        if (data.password !== data.passwordConfirm) {
            setError(
                "passwordConfirm",
                { type: String, message: "As senhas não coincidem." },
                { shouldFocus: true }
            );
        } else {
            // Mapeamento dos campos de perfil de investimento e gênero
            switch (data.investment_profile) {
                case "Conservador":
                    data.investment_profile = "CON";
                    break;
                case "Moderado":
                    data.investment_profile = "MOD";
                    break;
                case "Agressivo":
                    data.investment_profile = "AGR";
                    break;
                default:
                    break;
            }
            switch (data.gender) {
                case "Masculino":
                    data.gender = "M";
                    break;
                case "Feminino":
                    data.gender = "F";
                    break;
                case "Outros":
                    data.gender = "O";
                    break;
                default:
                    break;
            }

            // Normalização do número de celular
            data.phone_number = "+" + data.phone_number.match(/\d/g).join("");

            isLoading(true);
            amc_api
                .post("/users/create_user/", data, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((res) => {
                    // Login e redirecionamento
                    amc_api
                        .post("users/api_token_auth/", {
                            username: data.email,
                            password: data.password,
                        })
                        .then((res) => {
                            localStorage.setItem("token", res.data.token);
                            window.location.reload();
                        });
                })
                .catch((error) => {
                    if (error.response.data.code === 1001) {
                        setError(
                            "email",
                            {
                                type: String,
                                message: "Este email já existe.",
                            },
                            { shouldFocus: true }
                        );
                    }
                    if (error.response.data.code === 1003) {
                        setError(
                            "phone_number",
                            {
                                type: String,
                                message: "Este celular já existe.",
                            },
                            { shouldFocus: true }
                        );
                    }
                    if (error.response.data.code === 1002) {
                        setError(
                            "gender",
                            {
                                type: String,
                                message: "Este campo é obrigatório.",
                            },
                            { shouldFocus: true }
                        );
                    }
                })
                .finally(isLoading(false));
        }
    };

    const allFields = [
        {
            label: "E-mail",
            field: "email",
            options: { required: true },
        },
        {
            label: "Perfil",
            field: "investment_profile",
            options: [
                {
                    label: "Conservador",
                    value: "conservador",
                },
                {
                    label: "Moderado",
                    value: "moderado",
                },
                {
                    label: "Agressivo",
                    value: "AGR",
                },
            ],
        },
        {
            label: "Nome",
            field: "first_name",
            options: { required: "Nome é obrigatório" },
        },
        {
            label: "Sobrenome",
            field: "last_name",
            options: { required: false },
        },
        {
            label: "Celular (WhatsApp)",
            field: "phone_number",
            options: { required: "Celular é obrigatório" },
        },
        {
            label: "Gênero",
            field: "gender",
            options: [
                {
                    label: "Masculino",
                    value: "M",
                },
                {
                    label: "Feminino",
                    value: "F",
                },
                {
                    label: "Outros",
                    value: "O",
                },
            ],
        },
        {
            label: "Senha",
            field: "password",
            options: { required: "Insira uma senha" },
        },
        {
            label: "Confirmar senha",
            field: "passwordConfirm",
            options: { required: "Confirme a senha" },
        },
    ];

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="text-2xl font-bold">Criar conta</div>
            <div className="grid pt-4">
                {allFields.map((field, key) => (
                    <div className="field col-6" key={key}>
                        <FormField
                            label={field.label}
                            field={field.field}
                            register={register}
                            errors={errors}
                            options={field.options}
                        />
                    </div>
                ))}
            </div>

            {/* Checkbox para aceitar os termos */}
            <div className="pt-2">
                <input
                    type="checkbox"
                    id="terms"
                    checked={termsAccepted}
                    onChange={(e) => setTermsAccepted(e.target.checked)}
                />
                <label htmlFor="terms" className="pl-2 text-xs">
                    Concordo com os Termos de Serviço e a Política de Privacidade. Entendo que meus dados serão processados de acordo com a Lei Geral de Proteção de Dados (LGPD) e aceito receber comunicações relacionadas aos serviços.
                </label>
                <a
                    href="#"
                    className="text-blue-500 underline pl-2 text-sm"
                    onClick={(e) => {
                        e.preventDefault(); // Evita o comportamento padrão do link
                        setShowTermsModal(true);
                    }}
                >
                    Ler Termos de Uso
                </a>
            </div>

            {/* Adicionando espaço entre o link e o botão */}
            <Button
                type="submit"
                label={
                    loading ? (
                        <ProgressSpinner style={{ height: "18px" }} />
                    ) : (
                        "Criar conta"
                    )
                }
                className="mt-4 mb-4 w-full bg-green-600" // Adicionando margem superior para espaçamento
                disabled={loading || !termsAccepted} // Desabilita o botão se o checkbox não estiver marcado
            />

            <Dialog
                header="Termos de Uso do Alpha Monkey Club"
                visible={showTermsModal}
                style={{ width: '100vw', maxWidth: '100%' }} // Definindo largura total no mobile
                onHide={() => setShowTermsModal(false)}
                breakpoints={{'960px': '90vw', '640px': '100vw'}} // Definindo responsividade
            >
                {/* Conteúdo da modal */}
                <p><strong>1. Aceitação dos Termos</strong> Ao acessar e utilizar a plataforma Alpha Monkey Club, você concorda em cumprir e estar sujeito aos presentes Termos de Uso, bem como à nossa Política de Privacidade. Caso não concorde com algum dos termos, não utilize os serviços.</p>

                <p><strong>2. Serviços Oferecidos</strong> O Alpha Monkey Club oferece ferramentas de previsões de mercado financeiro com base em análises de Big Data e Machine Learning. As previsões e informações disponibilizadas visam auxiliar nas decisões de investimento, mas não garantem resultados financeiros e são de responsabilidade do usuário. Importante destacar que o Alpha Monkey Club não mantém nenhum valor sob custódia. As informações para automação não concedem acesso a dados pessoais, exceto ao código da posição aberta, que é encerrada ao final da operação realizada no plano Automated.</p>

                <p><strong>3. Cadastro de Usuários</strong> Para utilizar os serviços, é necessário criar uma conta fornecendo informações precisas e verdadeiras. Você é responsável por manter a confidencialidade de suas credenciais e por todas as atividades que ocorrerem sob sua conta.</p>

                <p><strong>4. Uso Adequado da Plataforma</strong> Você concorda em utilizar a plataforma de forma lícita, respeitando todas as leis aplicáveis. É proibido usar o Alpha Monkey Club para atividades ilegais ou que possam prejudicar o funcionamento da plataforma e a experiência de outros usuários.</p>

                <p><strong>5. Propriedade Intelectual</strong> Todos os direitos sobre o conteúdo, dados, previsões, design, marcas e demais elementos do Alpha Monkey Club são de propriedade exclusiva da empresa. É proibido copiar, distribuir ou reproduzir qualquer conteúdo sem autorização prévia.</p>

                <p><strong>6. Limitação de Responsabilidade</strong> O Alpha Monkey Club não se responsabiliza por perdas financeiras decorrentes de decisões de investimento baseadas nas previsões ou informações fornecidas pela plataforma. As previsões são baseadas em dados e algoritmos, mas não garantem precisão ou resultados futuros.</p>

                <p><strong>7. Modificações nos Termos</strong> O Alpha Monkey Club reserva-se o direito de alterar estes Termos de Uso a qualquer momento. As alterações serão publicadas na plataforma, e a continuidade no uso dos serviços após a atualização implica aceitação dos novos termos.</p>

                <p><strong>8. Cancelamento de Conta</strong> O usuário pode solicitar o cancelamento de sua conta a qualquer momento, entrando em contato com o suporte pelo WhatsApp. Em caso de violação dos Termos de Uso, o Alpha Monkey Club pode encerrar sua conta imediatamente, sem aviso prévio.</p>

                <p><strong>9. Lei Aplicável</strong> Estes Termos de Uso são regidos pelas leis do Canadá, onde a Alpha Monkey Club está localizada. Quaisquer disputas serão resolvidas conforme as leis canadenses.</p>

                {/* Fechar botão */}
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button label="Fechar" onClick={() => setShowTermsModal(false)} />
                </div>
            </Dialog>



        </form>
    );
}
