import React, { useState, useEffect, useRef } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { amc_api } from "../../utils/api_controller";
import { useParams, useNavigate } from "react-router-dom";
import PortfolioMetrics from "./PortfolioMetrics2";
import PortfolioAlphaMetrics from "./PortfolioAlphaMetrics";
import ShowDrawDown from "./ShowDrawDown";
import PortfolioChart from "./PortfolioChart3D";

const ShowPortifolioAutomated = () => {
    const { portfolioCode } = useParams(); // Obtém o código do portfólio pela URL
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [portfolioData, setPortfolioData] = useState(null);
    const [portfolioCorretoraData, setPortfolioCorretoraData] = useState(null);
    const [selectedPeriod, setSelectedPeriod] = useState("12M"); // Período padrão
    const [selectedGranularity, setSelectedGranularity] = useState("month"); // Granularidade padrão
    const [selectedBrokerage, setSelectedBrokerage] = useState(null); // Corretora selecionada
    const [showBrokerageDialog, setShowBrokerageDialog] = useState(false); // Controle do popup
    const portfolioRef = useRef(null);
    const navigate = useNavigate();

    const formatBRL = (number) =>
        number
            ? number.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
              })
            : "R$ 0,00";

    const fetchPortfolioDetails = async (period = "12M", granularity = "month") => {
        setLoading(true);
        setError(false);

        try {
            const portfolioCorretoraResponse = await amc_api.get(`/alphaportifolio/get_portfolio_by_code/${portfolioCode}/`);
            const portfolioCorretoraDetails = portfolioCorretoraResponse.data;

            setPortfolioCorretoraData({
                ...portfolioCorretoraDetails,
                portfolioCorretoraDetails,
            });

            const portfolioResponse = await amc_api.get(`/alphaportifolio/get_portfolio_by_code2/${portfolioCode}/${selectedBrokerage?.id || portfolioCorretoraDetails.brokerages[0].id}/${period}/${granularity}/`);
            const portfolioDetails = portfolioResponse.data;

            setPortfolioData({
                ...portfolioDetails,
                portfolioDetails,
            });

            setTimeout(() => {
                portfolioRef.current?.scrollIntoView({
                    behavior: "smooth",
                });
            }, 300);
        } catch (err) {
            console.error("Erro ao carregar dados do portfólio:", err);
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPortfolioDetails(selectedPeriod, selectedGranularity);
    }, [selectedPeriod, selectedGranularity, selectedBrokerage]);

    const handlePurchase = () => {
        if (!selectedBrokerage) {
            setShowBrokerageDialog(true); // Abre o popup se nenhuma corretora estiver selecionada
            return;
        }

        proceedToCart(selectedBrokerage);
    };

    const proceedToCart = (brokerage) => {
        const alphaDetails = {
            ativo: "PORTFOLIO",
            tipo: portfolioCorretoraData.portfolioCorretoraDetails.code,
            corretora: brokerage.id.toString(), // Convertendo o ID para string
        };
        console.log('alphaDetails -> ', alphaDetails)
        localStorage.setItem("purchaseDetails", JSON.stringify(alphaDetails));
        navigate("/cart");
    };

    const handleBrokerageSelect = (brokerage) => {
        setSelectedBrokerage(brokerage);
        setShowBrokerageDialog(false); // Fecha o popup
        proceedToCart(brokerage); // Navega diretamente para a tela de pagamento
    };

    if (loading) {
        return <ProgressSpinner />;
    }

    if (error) {
        return <div className="text-red-500">Erro ao carregar dados do portfólio.</div>;
    }

    const { portfolioCorretoraDetails } = portfolioCorretoraData;

    return (
        <div ref={portfolioRef} className="container">
            {/* Cabeçalho */}
            <div className="header mt-5 mb-2 p-1">
                <div className="identification text-left mb-4 pr-2">
                    <div className="text-3xl font-bold">Portfólio: {portfolioCorretoraDetails.code}</div>
                </div>
                </div>
                <div className="identification text-left mb-4 pr-2">
                    <div className="text-2xl text-700 mb-4">{portfolioCorretoraDetails.description}</div>
                </div>

            {/* Botões de seleção de período */}
            <div className="period-selection text-center mt-2">
                <div className="text-xl font-semibold mb-2">Selecione o Período:</div>
                <div className="grid grid-nogutter col-12 gap-2 justify-content-center">
                    {[
                        { label: "Desde 2022", value: "ALL" },
                        { label: "Ano de 2022", value: "2022" },
                        { label: "Ano de 2023", value: "2023" },
                        { label: "Ano de 2024", value: "2024" },
                        { label: "Últimos 12 meses", value: "12M" },
                        { label: "Últimos 6 meses", value: "6M" },
                        { label: "Últimos 3 meses", value: "3M" },
                        { label: "Último mês", value: "1M" },
                    ].map((period) => (
                        <Button
                            key={period.value}
                            label={period.label}
                            className={`p-button-outlined ${
                                selectedPeriod === period.value ? "p-button-primary" : ""
                            }`}
                            onClick={() => setSelectedPeriod(period.value)}
                        />
                    ))}
                </div>
            </div>

            {/* Botões de seleção de granularidade */}
            <div className="granularity-selection text-center mt-2">
                <div className="text-xl font-semibold mb-2">Selecione a Granularidade:</div>
                <div className="grid grid-nogutter col-12 gap-2 justify-content-center">
                    {[
                        { label: "Mensal", value: "month" },
                        { label: "Diária", value: "day" },
                    ].map((granularity) => (
                        <Button
                            key={granularity.value}
                            label={granularity.label}
                            className={`p-button-outlined ${
                                selectedGranularity === granularity.value ? "p-button-primary" : ""
                            }`}
                            onClick={() => setSelectedGranularity(granularity.value)}
                        />
                    ))}
                </div>
            </div>

            {/* Gráficos do portfólio e métricas */}
            <div className="flex flex-column w-full gap-3 mb-3">
                <PortfolioMetrics portfolio={portfolioData.portfolio} />
                <PortfolioChart portfolioData={portfolioData} />
            </div>

            {/* Botão de automação */}
            <div className="header mt-5 mb-2 p-1">
                <div className="cta mt-4">
                    <Button
                        label={`Comprar por ${formatBRL(portfolioCorretoraDetails.price)} por mês`}
                        type="button"
                        className="p-button-raised w-full"
                        onClick={handlePurchase}
                    />
                </div>
            </div>

            <div className="flex flex-column w-full gap-3 mb-3">
                <ShowDrawDown drawdownList={portfolioData.portfolio.metrics.drawdown_list} title="Lista de Drawdowns" />
            </div>

            {/* Gráficos do portfólio e métricas */}
            <div className="flex flex-column w-full gap-3 mb-3">
                <PortfolioAlphaMetrics alphas={portfolioData.alphas_win} type="WIN" />
                <PortfolioAlphaMetrics alphas={portfolioData.alphas_wdo} type="WDO" />
            </div>

            {/* Popup de seleção de corretora */}
            <Dialog
                header="Selecione a Corretora"
                visible={showBrokerageDialog}
                style={{ width: "50vw" }}
                modal
                onHide={() => setShowBrokerageDialog(false)}
            >
                <div className="flex flex-column gap-3">
                    {portfolioCorretoraDetails.brokerages.map((brokerage) => (
                        <Button
                            key={brokerage.id}
                            label={brokerage.name}
                            className={`p-button-outlined ${
                                selectedBrokerage?.id === brokerage.id ? "p-button-primary" : ""
                            }`}
                            onClick={() => handleBrokerageSelect(brokerage)}
                        />
                    ))}
                </div>
            </Dialog>
        </div>
    );
};

export default ShowPortifolioAutomated;
