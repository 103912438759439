import React, { useState, useEffect } from "react";
import { amc_api } from "../../utils/api_controller";
import AlphaProductBlock from "./AlphaProductBlock";

export default function FeaturedAlphas() {
    const [portfolios, setPortfolios] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        setLoading(true);
        amc_api
            .get("/alphaportifolio/get_all_portfolios/")
            .then((res) => {
                setPortfolios(res.data.portfolios || []);
            })
            .catch(() => setError(true))
            .finally(() => setLoading(false));
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error loading portfolios.</div>;
    }

    return (
        <div className="flex p-6 text-center justify-content-center">
            <div className="alpha-details">
                <div className="text-center text-3xl font-bold">
                    Portfólios disponíveis:
                </div>
                <div className="flex justify-content-center">
                    <div className="grid grid-nogutter col-12 gap-3 justify-content-center">
                        {portfolios.map((portfolio) => (
                            <AlphaProductBlock 
                                key={portfolio.id} 
                                portfolio_code={portfolio.code} 
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
